import React, {
    Dispatch,
    FC,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    useTransition,
} from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import Tab from '@sport1/news-styleguide/Tab'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import { useRouter } from 'next/router'
import { LabelProps, TagProps } from '@sport1/types/web'
import { useItemsRendered } from '../hooks/useItemsRendered'

import { TabbarConfig } from '../TabbarConfig'
import { LogoSport1 } from '../LogoSport1'
import { addPrimarySuffix } from '../utils/addPrimarySuffix'
import Burger from '../Burger'
import DropDownOverlay, { DropDownRenderItemProps } from '@/components/DropDownOverlay'
import { ArrowDropdown } from '@/components/TabBar/DropdownArrow'
import { TabBarItemType } from '@/components/TabBar/TabbarTypes'
import {
    getDropdownOverlayItems,
    renderOverlayItem,
    renderTabBarItems,
} from '@/components/TabBar/utils'
import { slugify } from '@/helpers/slugHelper'
import useOuterClick from '@/hooks/useOuterClick'

import { useTracking } from '@/utils/tracking/TrackingProvider'
import { useMobile } from '@/hooks/useMobile'

export type SportsNavBarProps = {
    items: (TagProps | LabelProps)[]
    setIsBurgerMenuOpen: Dispatch<SetStateAction<boolean>>
    setIsBurgerMenuOpenSportarten: React.Dispatch<React.SetStateAction<boolean>>
    isBurgerMenuVisible?: boolean
    pageTag?: TagProps | LabelProps
    backgroundColor?: 'onyx' | 'pearl'
}

const LOGO_WIDTH = 74

const SportsNavBar: FC<SportsNavBarProps> = ({
    items,
    setIsBurgerMenuOpen,
    setIsBurgerMenuOpenSportarten,
    pageTag,
    backgroundColor = 'pearl',
    isBurgerMenuVisible,
}) => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false)
    const { trackInteraction } = useTracking()
    const router = useRouter()
    const { isMobile } = useMobile()
    const wrapperRef = useRef(null)
    const [, startTransition] = useTransition()
    useOuterClick(wrapperRef, () => setShowDropdown(false))

    const tabbarItems = useMemo(() => {
        return items.map((item: TagProps | LabelProps) => {
            const { title } = item

            return {
                title,
                href: item.href,
                contextId: item.contextId,
            } as TabBarItemType
        })
    }, [items])

    const getFirstSelectedTab = useCallback(() => {
        const itemIndex = tabbarItems.findIndex(item =>
            router.asPath === item.href || (pageTag?.href && pageTag.href.includes(item.href))
                ? item
                : null
        )
        const currentPath = router.asPath
        if (currentPath === '/' || currentPath.includes('tv-video')) {
            return -2
        }
        return itemIndex
    }, [router, tabbarItems, pageTag])

    const [selectedTab, setSelectedTab] = useState(getFirstSelectedTab())

    useEffect(() => {
        setShowDropdown(false)
    }, [isBurgerMenuVisible])

    const { containerRef, numberItemsRendered } = useItemsRendered(
        tabbarItems,
        () => {
            if (!isBurgerMenuVisible) {
                return TabbarConfig.DROPDOWN_MEHR_WIDTH
            }

            return TabbarConfig.DROPDOWN_MEHR_WIDTH + LOGO_WIDTH + TabbarConfig.BURGER_WIDTH
        },
        true,
        isBurgerMenuVisible
    )

    const openSportarten = useCallback(
        () => startTransition(() => setIsBurgerMenuOpenSportarten(v => !v)),
        [setIsBurgerMenuOpenSportarten]
    )

    const trackPress = useCallback(
        (index: number, tag?: TagProps | LabelProps) => {
            const selectedTabItem = tag || tabbarItems[index]
            if (selectedTabItem) {
                trackInteraction({
                    devices: 'all',
                    interaction_category: 'navigation',
                    interaction_action: 'click',
                    interaction_label: `sports-navbar_${slugify(selectedTabItem?.title)}`,
                    platforms: isMobile ? 'mobile' : 'desktop',
                })
            }
        },
        [isMobile, tabbarItems, trackInteraction]
    )

    const callRenderOverlayItem = useCallback(
        ({ tag, index, href }: DropDownRenderItemProps): ReactElement => {
            const selectedItemIndex = selectedTab - numberItemsRendered

            if (!tag.url) {
                return renderOverlayItem({
                    tag,
                    currentTagIndex: index,
                    selectedItemIndex,
                    onPress: openSportarten,
                })
            }

            return renderOverlayItem({
                tag,
                currentTagIndex: index,
                selectedItemIndex,
                href,
                onPress: () => trackPress(index, tag),
            })
        },
        [selectedTab, numberItemsRendered, openSportarten, trackPress]
    )

    const onTabPress = useCallback(
        (index: number) => {
            setSelectedTab(index)
            trackPress(index)
        },
        [trackPress]
    )

    const renderTabBars = useMemo(() => {
        return renderTabBarItems({
            tabVariant: 'secondary',
            tabBarItems: tabbarItems,
            numberItemsRendered,
            onTabPress,
            selectedTab,
        })
    }, [tabbarItems, numberItemsRendered, onTabPress, selectedTab])

    return (
        <NonFlexingContainer elevation={2} backgroundColor={backgroundColor}>
            <NonFlexingContainer
                innerRef={containerRef}
                horizontal
                width="100%"
                maxWidth={1113}
                marginX="auto"
            >
                {isBurgerMenuVisible && (
                    <>
                        <Burger
                            isVisible={isBurgerMenuVisible}
                            side={isMobile ? 'Right' : 'Left'}
                            setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                        />
                        <LogoSport1 isVisible={isBurgerMenuVisible} />
                    </>
                )}

                <NonFlexingContainer
                    horizontal
                    overflow="hidden"
                    testID="sportsNavBar-tabBar-container"
                >
                    {renderTabBars}
                </NonFlexingContainer>
                <FlexingContainer
                    alignRight
                    centerVertical
                    zIndex={30}
                    testID="sportsNavBar-mehrTab-arrow-container"
                >
                    <NonFlexingContainer
                        display={
                            numberItemsRendered !== items.length
                                ? ['none', 'none', 'none', 'flex']
                                : 'flex'
                        }
                    >
                        <Tab
                            tabVariant="secondary"
                            title="MEHR"
                            onPress={() => {
                                onTabPress(-1)
                                openSportarten()
                            }}
                            isActive={selectedTab === -1}
                            testID="tabbar-mehr-tab"
                        />
                    </NonFlexingContainer>
                    <NonFlexingContainer
                        display={
                            numberItemsRendered !== items.length
                                ? ['flex', 'flex', 'flex', 'none']
                                : 'none'
                        }
                    >
                        <ArrowDropdown
                            setShowDropdown={() => setShowDropdown(show => !show)}
                            showDropdown={showDropdown}
                            testID={addPrimarySuffix(
                                'sportsNavBar-dropdown-container',
                                backgroundColor
                            )}
                        />
                    </NonFlexingContainer>
                </FlexingContainer>
                <NonFlexingContainer
                    innerRef={wrapperRef}
                    position="relative"
                    display={showDropdown ? 'flex' : 'none'}
                    testID="sportsNavBar-dropDownOverlay-container"
                >
                    <DropDownOverlay
                        tags={[
                            ...getDropdownOverlayItems(tabbarItems, numberItemsRendered),
                            {
                                contextType: 'TAG',
                                contextId: '',
                                title: 'Mehr',
                            } as TagProps,
                        ]}
                        renderItem={callRenderOverlayItem}
                        top={44}
                        testID={addPrimarySuffix(
                            'channel-tabBar-dropdown-overlay',
                            backgroundColor
                        )}
                        backgroundColor={backgroundColor}
                    />
                </NonFlexingContainer>
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default SportsNavBar
