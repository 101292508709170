import { sport1CondensedBlackItalic } from '@/helpers/fonts'

export const TabbarConfig = {
    TAB_WIDTH_WITHOUT_CONTENT: 32,
    DROPDOWN_ARROW_WIDTH: 60,
    DROPDOWN_MEHR_WIDTH: 70,
    FONT_FAMILY: sport1CondensedBlackItalic.style.fontFamily,
    FONT_SIZE: '14px',
    RULER_WIDTH: 'auto',
    RULER_POSITION: 'absolute',
    RULER_WHITESPACE: 'nowrap',
    BURGER_WIDTH: 44,
}
