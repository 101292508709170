import React, {
    Dispatch,
    FC,
    ReactElement,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import Tab from '@sport1/news-styleguide/Tab'
import { useRouter } from 'next/router'
import { LabelProps, TagNavigationItemProps, TagProps } from '@sport1/types/web'
import useTheme from '@sport1/react-elements/useTheme'
import { ThemeProps } from '@sport1/news-styleguide/Types'
import { useItemsRendered } from '../hooks/useItemsRendered'
import { TabbarConfig } from '../TabbarConfig'
import { addPrimarySuffix } from '../utils/addPrimarySuffix'
import Burger from '../Burger'
import { LogoSport1 } from '../LogoSport1'
import DropDownOverlay, { DropDownRenderItemProps } from '@/components/DropDownOverlay'
import useOuterClick from '@/hooks/useOuterClick'
import { ArrowDropdown } from '@/components/TabBar/DropdownArrow'
import {
    getDropdownOverlayItems,
    getUrlLastSlug,
    renderOverlayItem,
    renderTabBarItems,
} from '@/components/TabBar/utils'
import { TabBarItemType } from '@/components/TabBar/TabbarTypes'
import { slugify } from '@/helpers/slugHelper'
import { useTracking } from '@/utils/tracking/TrackingProvider'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { useMobile } from '@/hooks/useMobile'

export type SubChannelTabBarProps = {
    items: TagNavigationItemProps[]
    staticTabIcon?: string
    staticTabName?: string
    staticTabHref?: string
    backgroundColor?: string
    isBurgerMenuVisible?: boolean
    setIsBurgerMenuOpen: Dispatch<SetStateAction<boolean>>
}

const NON_ACTIVE_SLUGS = ['/news', '/video']

const SubChannelTabBar: FC<SubChannelTabBarProps> = ({
    items,
    staticTabIcon,
    staticTabName,
    staticTabHref,
    backgroundColor,
    isBurgerMenuVisible = true,
    setIsBurgerMenuOpen,
}) => {
    const router = useRouter()
    const { isMobile } = useMobile()
    const { themeName } = (useTheme() as ThemeProps['theme']) || {}

    const { trackInteraction } = useTracking()

    const tabbarItems = useMemo(() => {
        return items.map((item: TagNavigationItemProps) => {
            const { title } = item
            return {
                title,
                href: item.href || '',
                target: item.format === 'EXTERNAL_WEB_URL' ? '_blank' : undefined,
                contextId: '',
            } as TabBarItemType
        })
    }, [items])

    const getFirstSelectedTab = useCallback(() => {
        const currentPath = router.asPath
        const itemIndex = tabbarItems.findIndex(
            item =>
                currentPath === item.href ||
                (item.href.includes('/live-ticker') && currentPath.startsWith(item.href))
        )

        // Removes tab selected when tabbar is displayed in some subchannels
        if (
            new RegExp(NON_ACTIVE_SLUGS.join('|')).test(currentPath) &&
            !currentPath.startsWith('/team')
        ) {
            return -1
        }
        return itemIndex === -1 ? 0 : itemIndex
    }, [router.asPath, tabbarItems])

    const [selectedTab, setSelectedTab] = React.useState(getFirstSelectedTab())
    const [showDropdown, setShowDropdown] = React.useState<boolean>(false)
    const wrapperRef = useRef(null)
    useOuterClick(wrapperRef, () => setShowDropdown(false))
    const { containerRef, parentTabRef, numberItemsRendered } = useItemsRendered(
        tabbarItems,
        staticTabsWidth => {
            if (backgroundColor === 'pearl') {
                return staticTabsWidth + TabbarConfig.DROPDOWN_ARROW_WIDTH
            }

            return staticTabsWidth + TabbarConfig.DROPDOWN_ARROW_WIDTH + TabbarConfig.BURGER_WIDTH
        }
    )

    useEffect(() => {
        setShowDropdown(false)
    }, [isBurgerMenuVisible])

    const trackPress = useCallback(
        (index: number, tag?: TagProps | LabelProps) => {
            const selectedTabItem = tag || tabbarItems[index]
            const interactionLabel = `subchannel-navbar${slugify(
                `${staticTabName ? `_${staticTabName}` : ''}_${selectedTabItem.title}`
            )}`
            if (selectedTabItem) {
                trackInteraction({
                    devices: 'all',
                    interaction_category: 'navigation',
                    interaction_action: 'click',
                    interaction_label: interactionLabel,
                    platforms: isMobile ? 'mobile' : 'desktop',
                })
            }
        },
        [isMobile, staticTabName, tabbarItems, trackInteraction]
    )

    const callRenderOverlayItem = useCallback(
        ({ tag, index, href }: DropDownRenderItemProps): ReactElement => {
            const selectedItemIndex = selectedTab - numberItemsRendered
            return renderOverlayItem({
                tag,
                currentTagIndex: index,
                selectedItemIndex,
                href,
                onPress: () => trackPress(index, tag),
            })
        },
        [selectedTab, numberItemsRendered, trackPress]
    )

    const onTabPress = useCallback(
        (index?: number, selectTab = true) => {
            if (index && selectTab) {
                setSelectedTab(index)
            }
            const interactionLabel = `subchannel-navbar${slugify(
                `${staticTabName ? `_${staticTabName}` : ''}_${tabbarItems[index || 0].title}`
            )}`
            trackInteraction({
                devices: 'all',
                interaction_category: 'navigation',
                interaction_action: 'click',
                interaction_label: interactionLabel,
                platforms: isMobile ? 'mobile' : 'desktop',
            })
        },
        [isMobile, staticTabName, tabbarItems, trackInteraction]
    )

    const renderTabBars = useMemo(() => {
        return renderTabBarItems({
            tabVariant: themeName === 'dark' ? 'tertiary' : 'secondary',
            tabBarItems: tabbarItems,
            numberItemsRendered,
            onTabPress,
            selectedTab,
        })
    }, [tabbarItems, numberItemsRendered, onTabPress, selectedTab, themeName])
    return (
        <NonFlexingContainer
            elevation={2}
            testID={addPrimarySuffix('TabBar-SubChannelTabBar', backgroundColor)}
            backgroundColor={backgroundColor}
        >
            <NonFlexingContainer
                innerRef={containerRef}
                horizontal
                width="100%"
                maxWidth={1113}
                marginX="auto"
            >
                {isBurgerMenuVisible && (
                    <>
                        <Burger
                            setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                            isVisible={isBurgerMenuVisible}
                            side={isMobile ? 'Right' : 'Left'}
                        />
                        {!staticTabIcon && <LogoSport1 />}
                    </>
                )}
                {staticTabIcon && (
                    <NonFlexingContainer display="flex" innerRef={parentTabRef}>
                        <Tab
                            tabVariant={themeName === 'dark' ? 'tertiary' : 'secondary'}
                            title={staticTabName || getUrlLastSlug(router.query.slug)}
                            imageUrl={staticTabIcon}
                            alt={staticTabName}
                            href={staticTabHref || tabbarItems[0]?.href}
                            onPress={onTabPress}
                            webRel={nofollowExternal}
                        />
                    </NonFlexingContainer>
                )}

                <NonFlexingContainer
                    horizontal
                    overflow="hidden"
                    testID={addPrimarySuffix('subchanneltabbar-container', backgroundColor)}
                >
                    {renderTabBars}
                </NonFlexingContainer>
                <FlexingContainer
                    alignRight
                    centerVertical
                    zIndex={isBurgerMenuVisible ? 30 : 0}
                    backgroundColor={backgroundColor}
                    testID="subchanneltabbar-arrow-container"
                >
                    {numberItemsRendered !== items.length ? (
                        <ArrowDropdown
                            setShowDropdown={() => setShowDropdown(show => !show)}
                            showDropdown={showDropdown}
                            testID={addPrimarySuffix(
                                'subchanneltabbar-dropdown-container',
                                backgroundColor
                            )}
                        />
                    ) : null}
                </FlexingContainer>
                <NonFlexingContainer
                    innerRef={wrapperRef}
                    position="relative"
                    display={showDropdown ? 'flex' : 'none'}
                    testID="subchanneltabbar-dropDownOverlay-container"
                >
                    <DropDownOverlay
                        tags={getDropdownOverlayItems(tabbarItems, numberItemsRendered)}
                        renderItem={callRenderOverlayItem}
                        top={44}
                        backgroundColor={backgroundColor}
                        testID={addPrimarySuffix(
                            'subchanneltabbar-dropdown-overlay',
                            backgroundColor
                        )}
                    />
                </NonFlexingContainer>
            </NonFlexingContainer>
        </NonFlexingContainer>
    )
}

export default SubChannelTabBar
